import React, { useEffect, useState } from "react";
import Episode from "./components/Episode";
import Loading from "./components/Loading";
import ReactPlayer from "react-player";
import ErrorMessage from "./components/Error";
import moment from "moment";
import "./App.css";

function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const term = queryParams.get("station");
  const target = queryParams.get("target") ? queryParams.get("target") : null;
  const disableMore = queryParams.get("disableMore");
  const customLimit = queryParams.get("limit") ? queryParams.get("limit") : 12;
  const paginate =
    queryParams.get("paginate") && queryParams.get("paginate") === "true"
      ? true
      : false;
  const show_station = queryParams.get("show_station");
  const [station, setStation] = useState(null);
  const [episodes, setEpisodes] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [track, setTrack] = useState(
    "https://myradio-segments-scripts.s3.ap-southeast-2.amazonaws.com/1second.mp3"
  );
  const [streamProgress, setStreamProgress] = useState(0);
  const [streamPercent, setStreamPercent] = useState(0);
  const [duration, setDuration] = useState(0);
  const [buffering, setBuffering] = useState(false);
  const [length, setLength] = useState(0);
  const [player, setPlayer] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [init, setInit] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1);

  const playAudio = (e) => {
    setPlaying(!playing);
  };

  const newSeek = (percent) => {
    player.seekTo(percent / 100, "fraction");
  };

  const updateProgress = (progress) => {
    var per = (progress.playedSeconds / length) * 100;
    setStreamPercent(Math.floor(per));
    setStreamProgress(Math.floor(Math.floor(progress.playedSeconds)));
  };

  const updateTime = (event, div) => {
    const thisDiv = document.getElementById(div);
    const width = window.getComputedStyle(thisDiv);
    const currentWidth = parseInt(width["width"]);
    var test = (event.nativeEvent.offsetX - thisDiv.offsetLeft) / currentWidth;
    player.seekTo(test);
  };

  const updateDuration = (newDuration) => {
    setLength(Math.floor(newDuration));
    setDuration(
      moment("2015-01-01")
        .startOf("day")
        .seconds(Math.floor(newDuration))
        .format("H:mm:ss")
    );
  };

  // const updateSeek = (time) => {
  //   var diff = streamProgress + time;
  //   player.seekTo(diff > 0 ? diff : 0, "seconds");
  // };

  let ref = (player) => {
    setPlayer(player);
  };

  useEffect(() => {
    const getEpisodes = async () => {
      try {
        setLoading(true);
        const l = customLimit ? customLimit : 12;
        var req = await fetch(
          `${process.env.REACT_APP_API}/public/station/${term}/episodes?only_new=true&limit=${l}&page=${page}`
        );
        const results = await req.json();
        if (results.success === false) {
          setError(results.message);
        } else {
          if (results.data) {
            console.log("Here", results.data);
            const temp = episodes.concat(results.data.episodes.data);
            setEpisodes(temp);
            setLoading(false);
            setPage(results.data.episodes.current_page);
            setLimit(results.data.episodes.last_page);
            if (temp.length === 0) {
              setError("An Error has Occurred");
            } else {
              if (show_station !== null && station === null)
                setStation(temp[0].station);
              if (typeof window !== "undefined") {
                if (window.gtag) {
                  window.gtag("event", "show_station", {
                    station: temp[0].station.name,
                    stationId: temp[0].station.id,
                  });
                }
              }
            }
            sendPostMessage();
          }
        }
      } catch (error) {
        setLoading(false);
        setError("Oops, something went wrong here");
        console.error("here", error);
      }
    };

    if (!term) {
      setError("There was a proble finding this station");
    } else {
      getEpisodes();
    }
  }, [term, page]);

  useEffect(() => {
    const toggle = async () => {
      if (init === false) {
        await setInit(true);
        await setPlaying(true);
        await setPlaying(false);
        await setPlaying(true);
      } else {
        await setPlaying(true);
      }
      if (typeof window !== "undefined") {
        if (window.gtag) {
          window.gtag("event", "episode_played", {
            url: track,
            station: station.name,
            stationId: station.id,
          });
        }
      }
    };
    toggle();
  }, [track]);

  const sendPostMessage = () => {
    let height;
    setTimeout(function () {
      height = document.getElementById("container").offsetHeight;
      window.parent.postMessage(
        {
          frameHeight: height,
          eventName: "mrschedule",
        },
        "*"
      );
    }, 10);
  };

  return (
    <div className="max-w-7xl mx-auto" id="container">
      {track && (
        <ReactPlayer
          ref={ref}
          progressInterval={50}
          onDuration={(progress) => updateDuration(progress)}
          // onPlay={(item) => console.log("here", item)}
          playing={playing}
          onProgress={(progress) => updateProgress(progress)}
          controls={false}
          onBuffer={() => setBuffering(true)}
          onBufferEnd={() => setBuffering(false)}
          onEnded={() => setPlaying(false)}
          height={0}
          width={0}
          url={track}
          config={{
            file: {
              forceAudio: true,
            },
          }}
        />
      )}
      {station && station.image && (
        <div className="w-full p-4 text-center">
          <img
            className="mx-auto max-w-3xl w-full h-auto"
            src={station.image.url}
          />
        </div>
      )}
      {error && <ErrorMessage error={error} />}
      {episodes.length > 0 && (
        <div className="grid grid-cols-12 gap-4 p-2">
          {episodes.map((episode, i) => (
            <Episode
              sendPostMessage={sendPostMessage}
              disableMore={disableMore}
              updateTime={updateTime}
              newSeek={newSeek}
              buffering={buffering}
              episode={episode}
              setTrack={setTrack}
              playing={playing}
              track={track}
              index={i}
              key={i}
              playAudio={playAudio}
              streamProgress={streamProgress}
              streamPercent={streamPercent}
              duration={duration}
              config={{
                file: {
                  forceAudio: true,
                },
              }}
            />
          ))}
        </div>
      )}

      {loading && <Loading />}
      {episodes.length > 0 &&
        paginate === true &&
        loading === false &&
        page < limit && (
          <div className="w-full w-100 text-center">
            <button
              className="mx-auto mt-8 w-full max-w-4xl p-3 bg-gray-100 mb-5"
              onClick={(e) => {
                e.preventDefault();
                setPage(page + 1);
              }}
            >
              Load More
            </button>
          </div>
        )}
      <div className="w-full w-100 text-center mb-8 px-4">
        <p className="text-center text-sm text-gray-600">
          Built by{" "}
          <a href="https://nicklarosa.net" target="_new">
            Nick La Rosa
          </a>{" "}
          for{" "}
          <a href="https://myradio.click" target="_new">
            https://myradio.click
          </a>
        </p>
      </div>
    </div>
  );
}

export default App;
